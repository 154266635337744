
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import { Space, Button, Badge } from "antd";
import { DownOutlined, ShoppingOutlined } from "@ant-design/icons";
import LoginModalHandler from '../../containers/LoginModalHandler'


const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Space size="large" style={{
      lineHeight: "46px"
    }} mdxType="Space">
  <a href="/order">
    <Badge count={props.order.orderRows.length} mdxType="Badge">
      <Button type="link" style={{
            padding: 4
          }} mdxType="Button">
        <ShoppingOutlined style={{
              fontSize: 18
            }} mdxType="ShoppingOutlined" />
      </Button>
    </Badge>
  </a>
  <LoginModalHandler {...props.LoginModalHandlerRedirectToProps} mdxType="LoginModalHandler">
    {openLoginModal => <Button onClick={openLoginModal} type="link" mdxType="Button">
        Logga in
      </Button>}
  </LoginModalHandler>
    </Space>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;