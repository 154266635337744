import useBooleanState from '../hooks/useBooleanState'
import Modal from 'antd/lib/modal/Modal'
import qs from 'qs'
import { AnyChildren } from '../components/types'
import LoginModalContent from '../content/partials/login-modal.mdx'
import { getFullUrl } from '../utils/urls'

interface LoginModalHandlerProps {
  defaultRedirectTo: string,
  createdUserRedirectTo?: string,
  failedRedirectTo?: string,
  children?: (onOpen: () => void) => AnyChildren,
  autoOpen?: boolean
}

export default function LoginModalHandler({ defaultRedirectTo, createdUserRedirectTo, failedRedirectTo, children, autoOpen = false }: LoginModalHandlerProps) {
  const [modalIsOpen, openModal, closeModal] = useBooleanState(autoOpen)

  const queryStringFactory = (type: string) => qs.stringify({
    type,
    defaultRedirectTo,
    createdUserRedirectTo,
    failedRedirectTo,
    conditionsUrl: getFullUrl("/villkor"),
  })

  const smsLoginHref = `/api/auth/login?${queryStringFactory("sms")}`
  const bankidLoginHref = `/api/auth/login?${queryStringFactory("bankid")}`

  return (
    <>
      {children && children(openModal)}
      <Modal
        visible={modalIsOpen}
        footer={null}
        onCancel={closeModal}
      >
        <LoginModalContent
          smsLoginHref={smsLoginHref}
          bankidLoginHref={bankidLoginHref}
        />
      </Modal>
    </>
  )
}