
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */


const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Section = makeShortcode("Section");
const LinkButton = makeShortcode("LinkButton");
const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Section width="slender" verticalSpacing="medium" mdxType="Section">
      <h2>{`Logga in`}</h2>
      <h4>{`Välj inloggningsalternativ`}</h4>
      <p>{`För registrering av utbildningsintyg på ID06 krävs inloggning med BankID. `}</p>
      <Section verticalSpacing="small" mdxType="Section">
  <LinkButton href={props.bankidLoginHref} native mdxType="LinkButton">Logga in med BankID</LinkButton>
      </Section>
      <Section verticalSpacing="small" mdxType="Section">
  <LinkButton href={props.smsLoginHref} native mdxType="LinkButton">Logga in med SMS</LinkButton>
      </Section>
    </Section>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;