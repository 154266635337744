import qs from 'qs'
import { useMemo } from 'react'

import { LoadingOutlined } from '@ant-design/icons'
import TopbarRightContent from '../../content/partials/topbar-right.mdx'
import TopbarRightSignedInContent from '../../content/partials/topbar-right-signedin.mdx'

import { useUserProfile, useUserState } from '../../state/User'
import { useOrder } from '../../state/useOrder'
import { useRouter } from 'next/dist/client/router'
import { getFullUrl } from '../../utils/urls'

export default function TopbarRight() {

  const router = useRouter()
  const userState = useUserState()
  const userProfile = useUserProfile()

  const [, order] = useOrder()

  const LoginModalHandlerRedirectToProps = useMemo(() => {
    const [path, queryString] = router.asPath.split("?")

    const baseUrl = getFullUrl(path)
    const baseQuery = qs.parse(queryString)

    return {
      defaultRedirectTo: `${baseUrl}?${qs.stringify({ ...baseQuery, userWasSignedIn: "" })}`,
      createdUserRedirectTo: `${baseUrl}?${qs.stringify({ ...baseQuery, userWasCreated: "" })}`,
      failedRedirectTo: `${baseUrl}?${qs.stringify({ ...baseQuery, userFailedToSignIn: "" })}`,
    }
  }, [router.asPath])

  return (
    userState.hasPendingAuthentication ? (
      <LoadingOutlined spin />
    ) : (
      userState.isAuthenticated ? (
        <TopbarRightSignedInContent
          order={order}
          userProfile={userProfile}
        />
      ) : (
        <TopbarRightContent
          order={order}
          LoginModalHandlerRedirectToProps={LoginModalHandlerRedirectToProps}
        />
      )
    )
  )
}