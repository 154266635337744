
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import { Space, Dropdown, Menu, Button, Badge } from "antd";
import { DownOutlined, ShoppingOutlined } from "@ant-design/icons";


const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Space size="large" style={{
      lineHeight: "46px"
    }} mdxType="Space">
  <a href="/order">
    <Badge count={props.order.orderRows.length} mdxType="Badge">
      <Button type="link" style={{
            padding: 4
          }} mdxType="Button">
        <ShoppingOutlined style={{
              fontSize: 18
            }} mdxType="ShoppingOutlined" />
      </Button>
    </Badge>
  </a>
  <Dropdown trigger="click" arrow overlay={<Menu mdxType="Menu">
        <Menu.Item>
          <a style={{
            paddingRight: "var(--spacing-large)"
          }} href="/mina-utbildningar">
            Mina utbildningar
          </a>
        </Menu.Item>
        <Menu.Item>
          <a style={{
            paddingRight: "var(--spacing-large)"
          }} href="/utbildningsadministration">
            Uppföljningsvy
          </a>
        </Menu.Item>
        <Menu.Item>
          <a style={{
            paddingRight: "var(--spacing-large)"
          }} href="/mina-sidor">
            Mina sidor
          </a>
        </Menu.Item>
        <Menu.Item>
          <a style={{
            paddingRight: "var(--spacing-large)"
          }} href="/mina-sidor/installningar">
            Inställningar
          </a>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item>
          <a style={{
            paddingRight: "var(--spacing-large)"
          }} href="/api/auth/logout">
            Logga ut
          </a>
        </Menu.Item>
      </Menu>} mdxType="Dropdown">
    <Button type="link" mdxType="Button">
      {props.userProfile.given_name}
    </Button>
  </Dropdown>
    </Space>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;